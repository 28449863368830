import { Dialog, DialogContent } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { LoadingBar } from './loading-bar';

type Props = {
    timeToSkip?: number;
    timeToAutoClose?: number;
    source: string;
    alternativeText?: string;
    isActive: boolean;
    onFinish: () => void;
};

export const OverlayGif = ({ timeToAutoClose = 5000, source, alternativeText, isActive, onFinish }: Props) => {
    const [progress, setProgress] = useState(0);
    const delay = 200;

    useEffect(() => {
        if (isActive) {
            const timer = setInterval(
                () => {
                    setProgress((prev) => {
                        if (prev >= 100) {
                            clearInterval(timer);
                            setTimeout(onFinish, delay);
                        }
                        return prev + 1;
                    });
                },
                (timeToAutoClose - delay) / 100
            );
        }

        return () => {
            setProgress(0);
        };
    }, [isActive, onFinish, timeToAutoClose]);

    return (
        <>
            <Dialog
                open={isActive}
                fullWidth={true}
                PaperProps={{
                    sx: { width: { xs: '90vw', md: '40vw' }, maxWidth: 'none' },
                }}
            >
                <DialogContent sx={{ padding: '0px' }}>
                    <img
                        src={source}
                        alt={alternativeText}
                        loading="lazy"
                        style={{ display: 'block', width: '100%', objectFit: 'cover' }}
                    />
                </DialogContent>
                <div style={{ height: '10px' }}>
                    <LoadingBar
                        progress={progress}
                        delay={delay}
                    />
                </div>
            </Dialog>
        </>
    );
};
