import { OverlayGif } from './overlay-gif.comp';

type Props = {
    isActive: boolean;
    onFinish: () => void;
};

export const ShameOverlayGif = (props: Props) => {
    return OverlayGif({
        source: '/schande.gif',
        alternativeText: 'Schande.gif',
        isActive: props.isActive,
        onFinish: props.onFinish,
    });
};
