import React from 'react';
import './loading-bar.css';

type Props = {
    progress: number;
    delay: number;
};

export const LoadingBar = ({ progress, delay = 100 }: Props) => {
    return (
        <div className="loading-bar-container">
            <div
                className="loading-bar"
                style={{ width: `${progress}%`, transition: `width ${delay}ms` }}
            ></div>
        </div>
    );
};
