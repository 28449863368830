import { Checkbox, DialogActions, DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Fab from '@mui/material/Fab';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { DateTimePicker } from '@mui/x-date-pickers';
import * as React from 'react';
import { useState } from 'react';
import { useScreenSize } from '../../hooks/use-screen-size';
import { GameDto } from '../../model/game.dto';
import { NewGameDto } from '../../model/new-game.dto';
import { PlayerDto } from '../../model/player.dto';
import { TeamDto } from '../../model/team.dto';
import { AsyncButton } from '../async-button/async-button.comp';
import { ClosableDialogTitle } from '../closable-dialog-title/closable-dialog-title.comp';
import { ErrorDialog } from '../error-dialog/error-dialog.comp';
import { ResultButtons } from '../result-buttons/result-buttons.comp';
import { SelectPlayer } from '../select-player/select-player.comp';
import { SelectStandort } from '../select-standort/select-standort.comp';
import { SimpleSnackbar, SnackbarType } from '../simple-snackbar/simple-snackbar.comp';
import { useGameClient } from '../../clients/game-client/game-client';
import { LOCAL_STORAGE_NEW_GAME_STANDORT_KEY, useLocalStorage } from '../../hooks/use-local-storage';
import { ShameOverlayGif } from '../overlay-gif/shame-overlay-gif';
import { PartySituationOverlayGif } from '../overlay-gif/party-situation-overlay-gif';

export interface NewGameProps {
    players: PlayerDto[];
    onGameAdded: (game: GameDto) => void;
}

enum SaveButtonType {
    WINNERS_STAY,
    ALL_STAY,
    CLOSE,
}

export const NewGame = (props: NewGameProps) => {
    const { isMobile } = useScreenSize();

    const [playerIdA, setPlayerIdA] = useState(undefined);
    const [playerIdB, setPlayerIdB] = useState(undefined);
    const [playerIdC, setPlayerIdC] = useState(undefined);
    const [playerIdD, setPlayerIdD] = useState(undefined);
    const playerIds = [playerIdA, playerIdB, playerIdC, playerIdD];
    const setPlayerIds = [setPlayerIdA, setPlayerIdB, setPlayerIdC, setPlayerIdD];

    const [scoreTeamA, setScoreTeamA] = useState(undefined);
    const [scoreTeamB, setScoreTeamB] = useState(undefined);

    const [isLastGame, setIsLastGame] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);

    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
    const [error, setError] = useState<Response>();
    const [showWarningSnackbar, setShowWarningSnackbar] = useState(false);

    const [showDialog, setShowDialog] = useState(false);

    const [openSelectA, setOpenSelectA] = useState(false);
    const [openSelectB, setOpenSelectB] = useState(false);
    const [openSelectC, setOpenSelectC] = useState(false);
    const [openSelectD, setOpenSelectD] = useState(false);
    const openSelects = [openSelectA, openSelectB, openSelectC, openSelectD];
    const setOpenSelects = [setOpenSelectA, setOpenSelectB, setOpenSelectC, setOpenSelectD];

    const [clickedSavingButton, setClickedSavingButton] = useState(undefined);

    const [showShameGif, setShowShameGif] = useState(false);
    const [showPartySituationGif, setShowPartySituationGif] = useState(false);

    const [standort, setStandort] = useLocalStorage(LOCAL_STORAGE_NEW_GAME_STANDORT_KEY, 'K');

    const { addNewGame } = useGameClient();

    const resetPlayersTeamB = () => {
        setPlayerIdC(undefined);
        setPlayerIdD(undefined);
    };

    const resetPlayersTeamA = () => {
        setPlayerIdA(undefined);
        setPlayerIdB(undefined);
    };

    const onClickSaveButton = (type: SaveButtonType) => {
        setClickedSavingButton(type);
        if (formIsInvalid()) {
            setShowWarningSnackbar(true);
            setClickedSavingButton(undefined);
            return;
        }
        const teamA = new TeamDto(playerIdA, playerIdB);
        const teamB = new TeamDto(playerIdC, playerIdD);
        const newGameDto = new NewGameDto(
            teamA,
            teamB,
            scoreTeamA,
            scoreTeamB,
            isLastGame,
            selectedDate?.toISOString(true),
            standort
        );

        if (scoreTeamA === 0) {
            setShowShameGif(true);
        }
        if (scoreTeamB === 0) {
            setShowPartySituationGif(true);
        }

        addNewGame(newGameDto)
            .then((game: GameDto) => {
                props.onGameAdded(game);
                switch (type) {
                    case SaveButtonType.WINNERS_STAY:
                        if (scoreTeamA === 6) {
                            resetPlayersTeamB();
                        } else {
                            resetPlayersTeamA();
                        }
                        break;
                    case SaveButtonType.CLOSE:
                        setShowDialog(false);
                        resetPlayersTeamA();
                        resetPlayersTeamB();
                        break;
                }
                setShowSuccessSnackbar(true);
                setSelectedDate(null);
                setScoreTeamA(undefined);
                setScoreTeamB(undefined);
                setIsLastGame(false);
            })
            .catch((error) => setError(error))
            .finally(() => setClickedSavingButton(undefined));
    };

    const getAvailablePlayers = (selectedPlayerId: number) => {
        const selectedPlayerIds = [playerIdA, playerIdB, playerIdC, playerIdD];
        return props.players
            .filter((player) => player.active)
            .filter((player) => !selectedPlayerIds.includes(player.id) || player.id === selectedPlayerId);
    };

    const handleSelectTeamScore = (
        setTeamScore: (value: number) => void,
        setOtherScore: (value: number) => void,
        score: number,
        otherScore: number
    ) => {
        setTeamScore(score);
        if (score < 6) {
            setOtherScore(6);
        }
        if (score === 6 && otherScore === 6) {
            setOtherScore(undefined);
        }
    };

    const formIsInvalid = () => {
        return !(playerIdA && playerIdC && scoreTeamA >= 0 && scoreTeamB >= 0);
    };

    const getPlayer = (playerId: number | undefined) => {
        if (playerId) {
            return props.players.find((player) => player.id === playerId);
        }
    };

    const getSelectPlayer = (index: number) => (
        <SelectPlayer
            label={'Spieler ' + (index + 1)}
            player={getPlayer(playerIds[index])}
            onClick={() => setOpenSelects[index](true)}
            onCancel={() => setOpenSelects[index](false)}
            players={getAvailablePlayers(playerIds[index])}
            open={openSelects[index]}
            onSelectPlayer={(playerId) => {
                setPlayerIds[index](playerId);
                setOpenSelects[index](false);
                if (index + 1 < playerIds.length && !playerIds[index + 1]) {
                    setOpenSelects[index + 1](true);
                }
            }}
        />
    );

    const getSnackbars = () => {
        return (
            <>
                <ErrorDialog
                    message={`Das Spiel konnte nicht gespeichert werden. Server Antwort: ${JSON.stringify(error)}`}
                    onDismiss={() => setError(undefined)}
                    show={!!error}
                    title="Fehler"
                />
                <SimpleSnackbar
                    show={showSuccessSnackbar}
                    message="Spiel gespeichert"
                    onClose={() => setShowSuccessSnackbar(false)}
                    type={SnackbarType.SUCCESS}
                />
                <SimpleSnackbar
                    show={showWarningSnackbar}
                    message="Jedes Team muss mindestens einen Spieler haben und ein Ergebnis muss angegeben werden."
                    onClose={() => setShowWarningSnackbar(false)}
                    type={SnackbarType.WARNING}
                />
            </>
        );
    };

    const getDialogActions = () => (
        <DialogActions>
            <AsyncButton
                loading={clickedSavingButton === SaveButtonType.WINNERS_STAY}
                onClick={() => onClickSaveButton(SaveButtonType.WINNERS_STAY)}
                disabled={clickedSavingButton !== undefined}
                icon={<SaveIcon />}
                testid={'save_and_keep_winners_button'}
                text="(Gew.)"
            />
            <AsyncButton
                loading={clickedSavingButton === SaveButtonType.ALL_STAY}
                onClick={() => onClickSaveButton(SaveButtonType.ALL_STAY)}
                disabled={clickedSavingButton !== undefined}
                icon={<SaveIcon />}
                testid={'save_and_keep_all_button'}
                text="(Alle)"
            />
            <AsyncButton
                loading={clickedSavingButton === SaveButtonType.CLOSE}
                onClick={() => onClickSaveButton(SaveButtonType.CLOSE)}
                disabled={clickedSavingButton !== undefined}
                testid={'save_and_close_button'}
                icon={<SaveIcon />}
            />
        </DialogActions>
    );

    const getTwoSelectPlayers = (startingIndex: number) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {getSelectPlayer(startingIndex)}
            {getSelectPlayer(startingIndex + 1)}
        </div>
    );

    const getLastControlCheckbox = () => (
        <FormControlLabel
            label="Letztes Spiel"
            control={
                <Checkbox
                    checked={isLastGame}
                    onChange={(event) => setIsLastGame(event.target.checked)}
                />
            }
        />
    );

    const getDateTimePicker = () => (
        <div style={{ marginTop: 10 }}>
            <DateTimePicker
                ampm={false}
                format="DD.MM.YYYY HH:mm"
                disableFuture
                value={selectedDate}
                onChange={setSelectedDate}
                label={selectedDate ? 'Spielende' : 'Jetzt'}
                slotProps={{ actionBar: { actions: ['clear', 'accept'] } }}
            />
        </div>
    );

    const getDialogContent = () => (
        <DialogContent>
            <SelectStandort
                onChange={setStandort}
                selectedStandort={standort}
                style={{ width: '100%' }}
                showAllStandorteOption={false}
                disabled={false}
            />

            {getTwoSelectPlayers(0)}

            <ResultButtons
                selectedValue={scoreTeamA}
                onSelectValue={(value: number) => {
                    handleSelectTeamScore(setScoreTeamA, setScoreTeamB, value, scoreTeamB);
                }}
            />

            <ResultButtons
                selectedValue={scoreTeamB}
                onSelectValue={(value: number) => {
                    handleSelectTeamScore(setScoreTeamB, setScoreTeamA, value, scoreTeamA);
                }}
            />

            {getTwoSelectPlayers(2)}

            <br />

            {getLastControlCheckbox()}

            {getDateTimePicker()}
        </DialogContent>
    );

    return (
        <>
            <Fab
                variant={isMobile ? 'circular' : 'extended'}
                color="primary"
                onClick={() => setShowDialog(true)}
            >
                <AddIcon />
                {!isMobile && 'Neues Spiel eintragen'}
            </Fab>

            <Dialog
                open={showDialog}
                fullScreen={isMobile}
                onClose={() => setShowDialog(false)}
                TransitionProps={{ onEnter: () => setSelectedDate(null) }}
            >
                <ClosableDialogTitle
                    title={'Neues Spiel eintragen'}
                    onClose={() => setShowDialog(false)}
                />

                {getDialogContent()}

                {getDialogActions()}

                {showDialog ? getSnackbars() : null}
            </Dialog>

            <ShameOverlayGif
                isActive={showShameGif}
                onFinish={() => setShowShameGif(false)}
            />
            <PartySituationOverlayGif
                isActive={showPartySituationGif}
                onFinish={() => setShowPartySituationGif(false)}
            />

            {showDialog ? null : getSnackbars()}
        </>
    );
};
