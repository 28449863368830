import { TextField } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { ShameOverlayGif } from '../overlay-gif/shame-overlay-gif';

type Props = {
    name: string;
    setName: (name: string) => void;
    showNameInUseError: boolean;
    showNameUsesTrophyCharsError: boolean;
    onKeyDown: (event: React.KeyboardEvent) => void;
};

export const SelectPlayerName = ({
    name,
    setName,
    showNameInUseError,
    showNameUsesTrophyCharsError,
    onKeyDown,
}: Props) => {
    const [showGif, setShowGif] = React.useState(false);

    const errorText = showNameInUseError
        ? 'Name nicht mehr verfügbar'
        : showNameUsesTrophyCharsError
          ? 'Da will doch niemand schummeln'
          : '';

    useEffect(() => {
        setShowGif(showNameUsesTrophyCharsError);
    }, [showNameUsesTrophyCharsError]);

    return (
        <div>
            <TextField
                variant="standard"
                label="Name"
                value={name}
                autoFocus
                error={showNameInUseError || showNameUsesTrophyCharsError}
                helperText={errorText}
                onKeyDown={onKeyDown}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                style={{ marginBottom: 10, marginTop: 10, width: '100%' }}
            />
            {showNameUsesTrophyCharsError && (
                <ShameOverlayGif
                    isActive={showGif}
                    onFinish={() => setShowGif(false)}
                />
            )}
        </div>
    );
};
